<template>
  <section class="add-punte-modal history-modal" @mousedown="_clickModal">
    <p>
      Tratament: <b>{{ tratament }}</b>
    </p>
    <ul>
      <li>Punte intre dintii:</li>
      <li class="inline-block" v-for="(item, index) in punte" :key="index">
        <b
          ><span class="mleft2px">{{ item }}</span>
          <span v-if="index < punte.length - 1">|</span></b
        >
      </li>
    </ul>

    <div class="text-center">
      <delete-tratament-btn
        @delete-tratament="_deleteTratament"
        @set-delete-mode="_checkDelete"
      />
    </div>
  </section>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { historyModal } from '../mixins/history-modal';
  export default {
    name: 'add-punte-modal',
    mixins: [historyModal],
    data () {
      return {
        isDeletePrompt: false
      };
    },
    computed: {
      ...mapState({
        data: 'modalData',
        svg: 'svg',
        pacient: 'pacient',
        dantura: 'dantura'
      }),
      ...mapGetters({
        tratament: 'tratament'
      }),
      start () {
        return this.dantura[this.data.side][this.data.start];
      },
      punte () {
        return this.dantura[this.data.side]
          .slice()
          .splice(this.data.start, this.data.end + 1);
      }
    },
    methods: {
      deletePunte () {
        this.$store.dispatch('delete_punte');
      }
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/var";
  .add-punte-modal {
    width: 100%;
    max-width: 450px;
    padding: 24px;
    border-radius: 8px;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: @dark-grey;

    p {
      font-size: 1.6rem;
    }

    button {
      margin-top: 20px;
      background: none;
      svg circle {
        transition: fill 0.1s ease-in-out;
      }
      &:hover {
        background: none;
        svg circle {
          fill: @red;
        }
      }
    }
  }
</style>
